var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('window-header'),_c('section',[_c('article',[_c('field',{ref:"docs",staticStyle:{"top":"5px","left":"10px"},attrs:{"name":"docs","widget":"handsontable","readonly":"","height":325,"minRows":15,"columns":[
          { name: 'ndoc', header: 'Nº' },
          { name: 'fecha', header: 'Fecha', type: 'date2' },
          {
            name: 'tipo_id',
            header: 'Tipo',
            type: 'm2o',
            primary: 'codigo',
            label: 'nombre',
            showCode: true,
          },
          {
            name: _vm.esVenta() ? 'cliente_id' : 'proveedor_id',
            header: _vm.esVenta() ? 'Cliente' : 'Proveedor',
            type: 'm2o',
            primary: 'codigo',
            label: 'nombre_comercial',
            showCode: true,
          },
          { name: 'total', header: 'Total' } ]},nativeOn:{"dblclick":function($event){return _vm.onDblClick($event)}}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }