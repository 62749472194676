<template>
  <div>
    <window-header></window-header>
    <section>
      <article>
        <field
          @dblclick.native="onDblClick"
          ref="docs"
          name="docs"
          widget="handsontable"
          style="top: 5px; left: 10px"
          readonly
          :height="325"
          :minRows="15"
          :columns="[
            { name: 'ndoc', header: 'Nº' },
            { name: 'fecha', header: 'Fecha', type: 'date2' },
            {
              name: 'tipo_id',
              header: 'Tipo',
              type: 'm2o',
              primary: 'codigo',
              label: 'nombre',
              showCode: true,
            },
            {
              name: esVenta() ? 'cliente_id' : 'proveedor_id',
              header: esVenta() ? 'Cliente' : 'Proveedor',
              type: 'm2o',
              primary: 'codigo',
              label: 'nombre_comercial',
              showCode: true,
            },
            { name: 'total', header: 'Total' },
          ]"
        />
      </article>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "../components/rFormMixin.vue";
export default {
  mixins: [WindowMixin, rFormMixin],
  data: function () {
    return {
      title: "Importar líneas",
      primary: "ndoc",
    };
  },
  methods: {
    esVenta: function () {
      return this.winOptions.model.includes("cliente");
    },
    onDblClick() {
      var self = this;
      var h = self.$refs.docs.field.hotInstance;
      let row = h.getSelected();
      if (!row) return;
      row = row[0][0];
      let ndoc = h.getSourceData()[row]["ndoc"];
      let fields = [
        {
          name: "articulo_id",
          fields: [
            "codigo",
            "ean13",
            {
              name: "modelo_id",
              fields: ["codigo", "nombre"],
            },
            {
              name: "familia_id",
              fields: ["codigo", "nombre"],
            },
            {
              name: "tipo_comision_id",
              fields: ["codigo", "descripcion"],
            },
            "nombre",
            "unidad_medida_compra",
            "compra_metal_incluido",
            "recargo_equivalencia",
            "incluir_contraste_pcoste",
            "incluir_ocostes_pcoste",
            "precio_coste",
            "unidad_medida_venta",
            "venta_metal_incluido",
            "peso_unitario_fino",
            "margen_ultima_compra",
            {
              name: "taqueria_ids",
              fields: [
                "codigo",
                "nombre",
                { name: "thumbnail", fields: ["imagen"] },
                "precio_coste",
              ],
            },
            "taqueria_precio",
            { name: "thumbnail", fields: ["imagen"] },
            "tarifa1",
            "tarifa2",
            "tarifa3",
            "tarifa4",
            "tarifa5",
            "tarifa6",
            "tarifa7",
            "tarifa8",
            "tarifa9",
            "tarifa10",
          ],
        },
        "ean13",
        { name: "modelo_id", fields: ["codigo", "nombre"] },
        "descripcion",
        {
          name: "almacen_id",
          fields: ["codigo", "nombre"],
        },
        "unidades",
        "etiqueta",
        "peso",
        "mpl",
        {
          name: "taqueria_ids",
          fields: [
            "codigo",
            "nombre",
            { name: "thumbnail", fields: ["imagen"] },
            "precio_coste",
          ],
        },
        "incluir_taqueria",
        "taqueria_precio",
        "descuentos",
        "descuento_peso",
        "precio",
        "margen",
        "pventa",
        "totallinea",
        { name: "origin_pedido_id", fields: ["id"] },
        "unidades_facturadas",
        "peso_facturado",
      ];
      if (self.esVenta()) {
        fields = [
          ...fields,
          ...[
            "nota",
            "tt",
            "v",
            "m",
            "r",
            "tt2",
            "m2",
            "c2",
            "p",
            "u",
            {
              name: "com",
              fields: ["codigo", "descripcion"],
            },
            "c",

            "e",
            "precio_coste",
          ],
        ];
      } else {
        fields = [
          ...fields,
          ...[
            "refproveedor",
            "t",
            "c",
            "m",
            "r",
            "m2",
            "c2",
            "o",
            "coste_fijo",
            "v",
            "m3",
          ],
        ];
      }
      window.DB.getList("linea_" + self.winOptions.model, {
        fields,
      }).then(function (res) {
        res.data = res.data.filter((a) => a.articulo_id);
        res.data.forEach((v, i) => delete res.data[i]["id"]);
        self.winOptions.onSelect(res.data);
        self.$emit("close");
      });
    },
  },
  mounted() {
    var self = this;
    window.DB.getList(self.winOptions.model, {
      fields: [
        "ndoc",
        { name: "tipo_id", fields: ["nombre"] },
        "wt",
        "fecha",
        {
          name: self.esVenta() ? "cliente_id" : "proveedor_id",
          fields: ["nombre_comercial", "codigo"],
        },
        "total",
      ],
    }).then(function (res) {
      self.$set(self.formData, "docs", res.data);
    });
  },
};
</script>